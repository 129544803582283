export default class test {
  constructor() {
    this.input = {
      id: null,
      number: null,
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.id = data.id;
      this.input.deliveryNumber = data.deliveryNumber;
    }
    return this.input;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].deliveryNumber,
              type: "string",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
